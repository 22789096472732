import $ from "jquery";

class Search {
  // 1.Describe and create/initiate our object
  constructor() {
    this.addSearchHtml();
    this.mobileFormOrNot();
    this.resultsDiv = $("#search-overlay__results");
    this.openButton = $(".main-search__js-search-trigger");
    this.showInputButton = $(".main-search__show-input-wrapper svg");
    this.closeButton = $("body").not(".search-overlay");
    this.searchOverlay = $(".search-overlay");
    this.searchField = $("#search-term");

    this.mobileSearchFormExists = false;
    this.searchFieldMobile = $("#search-term-mobile");
    this.searchFormMobile = $(".main-search__mobile");
    this.showInputButtonMobile = $(".main-search__mobile-trigger");
    this.closeButtonMobile = $("#hamburguer-btn");
    this.mobileNavigation = $("#navbarSupportedContentY3");

    this.events();
    this.isOverlayOpen = false;
    this.spinnerWrapper = $(".main-search__loading-wrapper");
    this.isSpinnerVisible = false;
    this.previousValue;
    this.typingTimer;
    this.isResultEmpty = 3;
  }

  // 2. Events
  events() {
    this.openButton.on("click", this.openOverlay.bind(this));
    this.closeButton.on("click", this.closeOverlay.bind(this));
    $(document).on("keydown", this.keyPressDispatcher.bind(this));
    this.searchField.on("keyup", this.typingLogic.bind(this));
    this.showInputButton.on("click", this.showInput.bind(this));

    this.searchFieldMobile.on("keyup", this.typingLogicMobile.bind(this));
    this.showInputButtonMobile.on("click", this.showInputMobile.bind(this));
    $(document).on("keydown", this.keyPressDispatcher.bind(this));

    this.closeButtonMobile.on("click", this.closeInputMobile.bind(this));
  }

  // // 3. Methods (function, action...)
  typingLogic() {
    if (this.searchField.val() != this.previousValue) {
      clearTimeout(this.typingTimer);
      //   If input is not empty, display spinner
      if (this.searchField.val()) {
        if (!this.isSpinnerVisible) {
          $(".main-search__loading-wrapper").html(
            "<div class='spinner-loader'></div>"
          );
          this.isSpinnerVisible = true;
        }
        this.typingTimer = setTimeout(this.getResults.bind(this), 300);
      } else {
        $(".main-search__loading-wrapper").html(`
        <svg class="fill-text2" width="24" height="24" fill="none">
                <path d="M11.5 21.75c-5.65 0-10.25-4.6-10.25-10.25S5.85 1.25 11.5 1.25s10.25 4.6 10.25 10.25-4.6 10.25-10.25 10.25Zm0-19c-4.83 0-8.75 3.93-8.75 8.75s3.92 8.75 8.75 8.75 8.75-3.93 8.75-8.75-3.92-8.75-8.75-8.75ZM22 22.75c-.19 0-.38-.07-.53-.22l-2-2a.754.754 0 0 1 0-1.06c.29-.29.77-.29 1.06 0l2 2c.29.29.29.77 0 1.06-.15.15-.34.22-.53.22Z" />
            </svg>
        `);
        this.closeOverlay();
        this.isSpinnerVisible = false;
      }
    }
    this.previousValue = this.searchField.val();
  }

  // For mobile
  typingLogicMobile() {
    if (this.searchFieldMobile.val() != this.previousValue) {
      clearTimeout(this.typingTimer);
      //   If input is not empty, display spinner
      if (this.searchFieldMobile.val()) {
        if (!this.isSpinnerVisible) {
          $(".main-search__loading-wrapper").html(
            "<div class='spinner-loader'></div>"
          );
          this.isSpinnerVisible = true;
        }
        this.typingTimer = setTimeout(this.getResults.bind(this), 300);
      } else {
        $(".main-search__loading-wrapper").html(`
        <svg class="fill-text2" width="24" height="24" fill="none">
                <path d="M11.5 21.75c-5.65 0-10.25-4.6-10.25-10.25S5.85 1.25 11.5 1.25s10.25 4.6 10.25 10.25-4.6 10.25-10.25 10.25Zm0-19c-4.83 0-8.75 3.93-8.75 8.75s3.92 8.75 8.75 8.75 8.75-3.93 8.75-8.75-3.92-8.75-8.75-8.75ZM22 22.75c-.19 0-.38-.07-.53-.22l-2-2a.754.754 0 0 1 0-1.06c.29-.29.77-.29 1.06 0l2 2c.29.29.29.77 0 1.06-.15.15-.34.22-.53.22Z" />
            </svg>
        `);
        this.closeOverlay();
        this.isSpinnerVisible = false;
      }
      this.previousValue = this.searchFieldMobile.val();
    }
  }

  // ============================== Get Results ===========================
  getResults() {
    $.getJSON(
      galstianData.root_url +
        "/wp-json/galstian/v1/search?term=" +
        this.searchField.val(),
      (results) => {
        this.resultsDiv.html(`
        <div>
        ${
          results.brand.length
            ? `<div class="flex gap-2 mb-7 [&:not(:first-child)]:mt-7">
        <div class="text-text2">
        <span>Brands <span>
          <span class="inline-block">
        <span class="flex leading-none [font-feature-settings:'ss01'] rounded-xl px-[6px] pt-1 bg-[#f0f0f0] [border:1px_solid_#e0e0e0]" >` +
              '+' + results.brand.length +
              `</span></div><div  class="mr-auto"><a target="_blank" class="text-primary hover:text-link" href="` +
              galstianData.root_url +
              `/?s=` +
              this.searchField.val() +
              `">See All Results</a></div></div>
        <ul class="">`
            : ""
        }
          ${results.brand
            .slice(0, 2)
            .map(
              (
                item
              ) => `<li class="flex items-center h-12 gap-3 py-3 [&:not(:last-child)]:my-3"><a target="_blank" class="block search-result__image-wrapper" href="${
                item.permalink
              }"><img class=" rounded-xl h-12" src="${item.image}" /></a>
          <div class="search-result__text-wrapper"><a target="_blank" class="block" href="${item.permalink}">${
                item.title
              }</a></div></li>`
            )
            .join("")}
          ${results.brand.length ? "</ul>" : ""}

          ${
            results.blogPosts.length
              ? `<div class="flex gap-2 mb-7 [&:not(:first-child)]:mt-7">
          <div class="text-text2">
          <span>Blog posts<span>
            <span class="inline-block">
          <span class="flex leading-none [font-feature-settings:'ss01'] rounded-xl px-[6px] pt-1 bg-[#f0f0f0] [border:1px_solid_#e0e0e0]" >` +
                '+' + results.blogPosts.length +
                `</span></div><div  class="mr-auto"><a target="_blank" class="text-primary hover:text-link" href="` +
                galstianData.root_url +
                `/?s=` +
                this.searchField.val() +
                `">See All Results</a></div></div>
          <ul class="">`
              : ""
          }
            ${results.blogPosts
              .slice(0, 2)
              .map(
                (
                  item
                ) => `<li class="flex items-center h-12 gap-3 py-3 [&:not(:last-child)]:my-3"><a target="_blank" class="block search-result__image-wrapper" href="${
                  item.permalink
                }"><img class="rounded-xl h-12" src="${item.image}" /></a>
            <div class="search-result__text-wrapper"><a target="_blank" class="block" href="${item.permalink}">${
                  item.title
                }</a> ${
                  item.postType == "post"
                    ? `<span class="text-text2 text-sm">by ${item.authorName} </span>`
                    : (this.isResultEmpty = false)
                }</div></li>`
              )
              .join("")}
            ${results.blogPosts.length ? "</ul>" : ""}
          
          ${
            results.pages.length
              ? `<div class="flex gap-2 mb-7 [&:not(:first-child)]:mt-7 g">
          <div class="text-text2">
          <span>pages<span>
            <span class="inline-block">
          <span class="flex leading-none [font-feature-settings:'ss01'] rounded-xl px-[6px] pt-1 bg-[#f0f0f0] [border:1px_solid_#e0e0e0]" >` +
                '+' + results.pages.length +
                `</span></div><div  class="mr-auto"><a target="_blank" class="text-primary hover:text-link" href="` +
                galstianData.root_url +
                `/?s=` +
                this.searchField.val() +
                `">See All Results</a></div></div>
          <ul class="">`
              : ""
          }
            ${results.pages
              .slice(0, 2)
              .map(
                (
                  item
                ) => `<li class="flex items-center h-12 gap-3 py-3 [&:not(:last-child)]:my-3"><a target="_blank" class="block search-result__image-wrapper" href="${
                  item.permalink
                }"><img class="w-12 rounded-xl h-12" src="${
                  item.image
                    ? item.image
                    : galstianData.root_url +
                      "/wp-content/themes/palafex-theme/assets/img/icon/logo.svg"
                }" /></a>
            <div class="search-result__text-wrapper"><a target="_blank" class="block" href="${item.permalink}">${
                  item.title
                }</a></div></li>`
              )
              .join("")}
            ${results.pages.length ? "</ul>" : ""}

          
          ${
            results.product.length
              ? `<div class="flex gap-2 mb-7 [&:not(:first-child)]:mt-7">
          <div class="text-text2">
          <span>Products<span>
            <span class="inline-block">
          <span class="flex leading-none [font-feature-settings:'ss01'] rounded-xl px-[6px] pt-1 bg-[#f0f0f0] [border:1px_solid_#e0e0e0]" >` +
                '+' + results.product.length +
                `</span></div><div  class="mr-auto"><a target="_blank" class="text-primary hover:text-link" href="` +
                galstianData.root_url +
                `/?s=` +
                this.searchField.val() +
                `">See All Results</a></div></div>
          <ul class="">`
              : ""
          }
            ${results.product
              .slice(0, 2)
              .map(
                (
                  item
                ) => `<li class="flex items-center h-12 gap-3 py-3 [&:not(:last-child)]:my-3"><a target="_blank" class="search-result__image-wrapper block" href="${
                  item.permalink
                }"><img class="w-12 h-12 rounded-xl" src="${
                  item.image
                    ? item.image
                    : galstianData.root_url +
                      "/wp-content/themes/palafex-theme/assets/img/icon/logo.svg"
                }" /></a>
            <div class="search-result__text-wrapper"><a target="_blank" class="block" href="${item.permalink}">${
                  item.title
                }</a></div></li>`
              )
              .join("")}
            ${results.product.length ? "</ul>" : ""}


          ${
            results.brand.length == 0 &&
            results.blogPosts.length == 0 &&
            results.pages.length == 0 &&
            results.product.length == 0
              ? `<div>
              <div>No Results found for«` +
                this.searchField.val() +
                `» please check the search term, or visit the sections below.</div>
              <div class=" mt-4">
                <div><a class="text-primary hover:text-link" target="_blank" href="` +
                galstianData.brand_url +
                `">
                brands</a></div>
                <a class="text-primary hover:text-link" target="_blank" href="` +
                galstianData.blog_url +
                `">Blog</a></div>
                <div><a class="text-primary hover:text-link" target="_blank" href="` +
                galstianData.product_url +
                `">Produts</a></div>
              </div>
            </div>`
              : ""
          }
        </div>
      `);

        // Other stuff
        this.isSpinnerVisible = false;
        $(".main-search__loading-wrapper").html(`
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none">
  <path d="M26.6533 24.6211L20.3955 18.3047C21.6201 16.7695 22.3584 14.8242 22.3584 12.709C22.3584 7.75781 18.3447 3.74414 13.3877 3.74414C8.43066 3.74414 4.41699 7.76367 4.41699 12.7148C4.41699 17.666 8.43066 21.6797 13.3877 21.6797C15.5322 21.6797 17.4951 20.9297 19.042 19.6758L25.2588 25.9512C25.4463 26.1504 25.7041 26.25 25.9561 26.25C26.1963 26.25 26.4365 26.1621 26.6182 25.9863C27.0049 25.6172 27.0166 25.0078 26.6533 24.6211ZM13.3877 19.752C11.5068 19.752 9.73731 19.0195 8.40723 17.6895C7.07715 16.3594 6.34473 14.5898 6.34473 12.7148C6.34473 10.834 7.07715 9.06445 8.40723 7.74023C9.73731 6.41016 11.5068 5.67773 13.3877 5.67773C15.2686 5.67773 17.0381 6.41016 18.3682 7.74023C19.6982 9.07031 20.4307 10.8398 20.4307 12.7148C20.4307 14.5957 19.6982 16.3652 18.3682 17.6895C17.0381 19.0195 15.2686 19.752 13.3877 19.752Z" fill="#CDA274"/>
</svg>
      `);

        this.searchOverlay.addClass("search-overlay--active");
      }
    );
  }

  keyPressDispatcher(e) {
    if (e.keyCode == 27 && this.isOverlayOpen) {
      this.escapeOverlay();
    }
  }

  openOverlay() {
    this.searchOverlay.addClass("search-overlay--active");
    this.isOverlayOpen = true;
  }

  // Close overlay onclick outside
  closeOverlay(e) {
    if (!e.target.matches(".search-overlay, .search-overlay *")) {
      this.searchOverlay.removeClass("search-overlay--active");
    }
  }

  escapeOverlay() {
    this.searchOverlay.removeClass("search-overlay--active");
  }

  showInput() {
    console.log('dada');
    $('#menu-main-menu>li>a').toggleClass("max-1450:!pl-3");

    this.searchField.animate({ width: "toggle" });
    this.searchField.trigger("focus");
  }

  showInputMobile() {
    this.searchFormMobile.slideDown();
    this.showInputButtonMobile.slideUp();
    this.mobileNavigation.removeClass("show");
    document.getElementById('search-term').focus();
  }
  closeInputMobile() {
    this.searchFormMobile.slideUp();
    this.showInputButtonMobile.slideDown();
  }

  addSearchHtml() {
    $(".main-search__wrapper").append(`
    <div class="search-overlay">
      
      <div class="">
        <div id="search-overlay__results">
        </div>
      </div>
    </div>
    `);
  }

  // If it's mobile menu, display the search in different place
  // Also display the social isons in different place
  mobileFormOrNot() {
    var tablet = window.matchMedia("(max-width: 991px)");
    if (!this.mobileSearchFormExists && tablet.matches) {
      this.mobileSearchFormExists = true;
      $(".main-search__wrapper").appendTo(".main-search__mobile");
      $(".main-nav__social-icons").appendTo(".navbar-collapse");
    } 
  }
}

export default Search;
